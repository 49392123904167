import { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import Grid from '@material-ui/core/Grid';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UpdateIcon from '@material-ui/icons/Update';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';

import Designs from './Designs';
import HelperButton from './HelperButton';
import Links from './Links';
import PipelineVersion from './PipelineVersion';
import Polygons from './Polygons';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: 16,
    top: 80,
    zIndex: 1900,
  },
  spinner: {
    color: theme.palette.text.primary,
  },
  theYellowOne: {
    color: '#FFF500',
  },
}));

const Helpers = observer(() => {
  const classes = useStyles();
  const [rerunningPipeline, setRerunningPipeline] = useState(false);
  const rerunPipeline = async () => {
    setRerunningPipeline(true);
    try {
      await axios.post(
        'https://us-central1-sinuous-axiom-201618.cloudfunctions.net/prod-point-cloud-runner',
        { storage_id: Store.geoStorageId, start: 'prep_work' }
      );
    } catch (e) {
      setRerunningPipeline(false);
    }
    setRerunningPipeline(false);
  };

  return (
    <Grid className={classes.root}>
      <Grid item>
        <HelperButton
          color={Store.showSunlight ? 'primary' : 'default'}
          icon={
            Store.showSunlight ? (
              <WbSunnyIcon className={classes.theYellowOne} />
            ) : (
              <WbSunnyOutlinedIcon />
            )
          }
          onClick={Store.toggleShowSunlight}
        >
          Sunlight
        </HelperButton>
      </Grid>
      <Grid item>
        <HelperButton
          icon={<FlipCameraIosIcon />}
          onClick={Store.resetCameraControls}
        >
          Reset Camera
        </HelperButton>
      </Grid>
      <Grid item>
        <HelperButton
          icon={Store.showGrid ? <GridOnIcon /> : <GridOffIcon />}
          onClick={() => Store.setShowGrid(!Store.showGrid)}
        >
          {Store.showGrid ? 'Hide' : 'Show'} Grid
        </HelperButton>
      </Grid>
      <Grid item>
        <Links />
      </Grid>
      <Grid item>
        <HelperButton
          icon={Store.showWireframe ? <HomeOutlinedIcon /> : <HomeIcon />}
          onClick={Store.toggleShowWireframe}
        >
          {Store.showWireframe ? 'Hide' : 'Show'} Wireframe
        </HelperButton>
      </Grid>
      {Store?.metadata?.roof_planes?.[0]?.optimized_polygon && (
        <Grid item>
          <Polygons />
        </Grid>
      )}
      <Grid item>
        <PipelineVersion />
      </Grid>
      <Grid item>
        <HelperButton
          icon={
            rerunningPipeline ? (
              <CircularProgress className={classes.spinner} />
            ) : (
              <UpdateIcon />
            )
          }
          onClick={rerunPipeline}
          disabled={rerunningPipeline}
        >
          Rerun Pipeline
        </HelperButton>
      </Grid>
      <Grid item>
        <Designs />
      </Grid>
      <Grid item>
        <HelperButton onClick={Store.toggleShowTrees}>
          {Store.showTrees ? 'Hide Trees' : 'Show Trees'}
        </HelperButton>
      </Grid>
    </Grid>
  );
});

export default Helpers;
