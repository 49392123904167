import { useRef } from 'react';

import { DoubleSide, Math as ThreeMath, RepeatWrapping } from 'three';

import { useLoader } from 'react-three-fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

import Store from 'store/Store';
import { observer } from 'mobx-react-lite';

import asphaltMap from 'assets/Textures/Roof/AsphaltShingles/map.jpg';
import roofingTileMap from 'assets/Textures/Roof/RoofingTiles/Color.jpg';

import PolygonMesh from 'components/PolygonMesh';

import './IrradianceMapMaterial';

const ROOF_TYPES = {
  ASPHALT_SHINGLES: asphaltMap,
  ROOFING_TILES: roofingTileMap,
};

const RoofPlane = observer(
  ({
    azimuth,
    color: gradientColor,
    index,
    plane,
    sunhourPoints,
    tilt,
    visible,
  }) => {
    const mesh = useRef();
    const map = useLoader(TextureLoader, ROOF_TYPES[Store.roofType]);
    const colorMap = map.clone();
    colorMap.needsUpdate = true;
    colorMap.rotation = ThreeMath.degToRad(azimuth);
    colorMap.wrapS = RepeatWrapping;
    colorMap.wrapT = RepeatWrapping;
    colorMap.repeat.set(0.2, 0.2);

    const color =
      gradientColor ||
      (() => {
        const randomBetween = (min, max) =>
          min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        return `rgb(${r},${g},${b})`;
      })();

    // function addPanel(e) {
    //   const intersection = e.intersections[0];
    //   const { point } = intersection;
    //   setPanels([...panels, { position: point, tilt, azimuth }]);
    // }

    // function onPointerMove(event) {
    //   // if (!first) {
    //   //   return;
    //   // }
    //   if (event.isPrimary) {
    //     const intersects = event.intersections;
    //     if (intersects.length > 0 && helper.current) {
    //       helper.current.position.set(0, 0, 0);
    //       helper.current.lookAt(intersects[0].face.normal);
    //       helper.current.position.copy(intersects[0].point);
    //       // .add(intersects[0].face.normal);
    //     } else {
    //       helper.current.scale = [0, 0, 0];
    //     }
    //   }
    // }

    return (
      <>
        <PolygonMesh
          points={plane}
          azimuth={azimuth}
          index={index}
          receiveShadow
          ref={mesh}
          rotation={[-Math.PI / 2, 0, 0]}
          tilt={tilt}
          visible={Boolean(visible)}
          wireframe={Store.showWireframe}
        >
          {Store.showSunlight ? (
            <irradianceMapMaterial
              attach="material"
              side={DoubleSide}
              shaderUniforms={{
                sunhourPoints,
              }}
            />
          ) : (
            <meshStandardMaterial
              attach="material"
              map={colorMap}
              color={color}
              side={DoubleSide}
            />
          )}
        </PolygonMesh>
      </>
    );
  }
);

export default RoofPlane;
