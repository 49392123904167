import { createMuiTheme } from '@material-ui/core/styles';

const BaseThemeProps = {
  typography: {
    fontFamily: ['Heebo-Regular', 'Proxima-Nova-Regular'],
    button: {
      fontFamily: ['Montserrat-Bold', 'Proxima-Nova-Regular'],
      fontSize: '.875rem', //14px
    },
    h1: {
      fontFamily: ['Montserrat-Bold', 'Proxima-Nova-Regular'],
      fontSize: '	2.8125rem', // 45px
    },
    h2: {
      fontFamily: ['Montserrat-Bold', 'Proxima-Nova-Regular'],
      fontSize: '1.75rem', // 28px
    },
    h3: {
      fontFamily: ['Montserrat-Light', 'Proxima-Nova-Regular'],
      fontSize: '1rem', // 16px
    },
    subtitle1: {
      fontFamily: ['Montserrat-ExtraBold', 'Proxima-Nova-Regular'],
      fontSize: '.875rem', //14px
    },
    body1: {
      fontSize: '.875rem', //14px
    },
    body2: {
      fontSize: '.75rem', //12px
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#4CDDD4',
      contrastText: '#000',
      accent: '#64ada7',
    },
    secondary: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      header: '#FAFAFA',
      hover: '#F8F8F8',
    },
  },
  zIndex: {
    tooltip: 9999,
    modal: 9001,
    appBar: 1100,
    snackbar: 9999,
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: '#FFF',
        borderRadius: 12,
      },
      containedPrimary: {
        color: '#1D1D1D',
      },
    },
  },
};

const theme = createMuiTheme(BaseThemeProps);

export { BaseThemeProps };

export default theme;
