import axios from 'axios';

const fetchAutomatedDesign = async automatedDesignId => {
  try {
    const { data } = await axios.get(
      `https://api.gosolo.io/api/v1/automated_solar_design/${automatedDesignId}`
    );
    return data;
  } catch (e) {
    return null;
  }
};

export default fetchAutomatedDesign;
