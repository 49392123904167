import { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ViewModuleIcon from '@material-ui/icons/ViewModule';

import HelperButton from './HelperButton';

import Store from 'store/Store';
import { observer } from 'mobx-react-lite';

import { Link, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  disabled: {
    color: '#0060E0',
    // opacity: 0.3,
    cursor: 'default',
  },
  link: {
    textDecoration: 'none',
    color: '#0060E0',
    '&:hover': {
      color: '#005393',
    },
  },
}));

const Designs: React.FC = observer(() => {
  const classes = useStyles();
  const { automatedDesignId }: any = useParams();
  const designIds = Store?.metadata?.automated_design_ids;
  if (!designIds || !designIds.length) return null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HelperButton onClick={handleClick} icon={<ViewModuleIcon />}>
        Designs
      </HelperButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {designIds.map((designId: number) => (
          <MenuItem
            disabled={designId === Number(automatedDesignId)}
            key={designId}
          >
            {designId === Number(automatedDesignId) ? (
              <Typography className={classes.disabled} variant="h6">
                {designId}
              </Typography>
            ) : (
              <Link className={classes.link} to={`/design/${designId}`}>
                <Typography variant="h6">{designId}</Typography>
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default Designs;
