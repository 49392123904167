import DateFnsUtils from '@date-io/date-fns';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import Router from './router/Router';

import theme from './theme';

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          style={{
            height: '100vh',
            position: 'relative',
            background: '#303030',
            overflow: 'hidden',
          }}
        >
          <Router />
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
