import { makeObservable, observable, action } from 'mobx';
import { Math as ThreeMath } from 'three';

import getCenterPoint from '../utils/getCenterPoint';

export const FOOTPRINT_TYPES = {
  BRICK: 'BRICK',
  STUCCO: 'STUCCO',
};

export const GROUND_TYPES = {
  CONCRETE: 'CONCRETE',
  DIRT: 'DIRT',
  GRASS: 'GRASS',
  SAND: 'SAND',
};

export const ROOF_TYPES = {
  ASPHALT_SHINGLES: 'ASPHALT_SHINGLES',
  ROOFING_TILES: 'ROOFING_TILES',
};

export const MODES = {
  VIEW_MODE: 'VIEW_MODE',
  ROOF_FACE_SELECTION_MODE: 'ROOF_FACE_SELECTION_MODE',
  SELECTED_ROOF_FACE_MODE: 'SELECTED_ROOF_FACE_MODE',
};

export const ROOF_POLYGON_TYPES = {
  ALPHASHAPE: 'ALPHASHAPE',
  OPTIMIZED_1: 'OPTIMIZED_1',
  OPTIMIZED_2: 'OPTIMIZED_2',
};

export const modeGuardedFunction = (fn, currentMode, modesToCheck) => {
  if (!modesToCheck.includes(currentMode) && currentMode !== modesToCheck) {
    return () => {};
  }
  return fn;
};

class Store {
  constructor() {
    makeObservable(this, {
      //OBSERVABLES
      cameraControls: observable,
      currentMode: observable,
      designData: observable,
      expandRoofFaces: observable,
      footprintType: observable,
      geoStorageId: observable,
      groundSize: observable,
      groundType: observable,
      hoveredRoofPlane: observable,
      index: observable,
      metadata: observable,
      playingSunPath: observable,
      roofPlanes: observable,
      roofPolygonType: observable,
      roofType: observable,
      rotation: observable,
      showCompass: observable,
      showGrid: observable,
      showOptimizedPolygons: observable,
      showInterface: observable,
      showSunlight: observable,
      showTrees: observable,
      showWireframe: observable,
      sunPathDate: observable,
      //ACTIONS
      changeMode: action,
      hoverPlane: action,
      resetCameraControls: action,
      setCameraControls: action,
      setDesignData: action,
      setFootprintType: action,
      setGeoStorageId: action,
      setGroundSize: action,
      setGroundType: action,
      setIndex: action,
      setMetadata: action,
      setRoofPlanes: action,
      setRoofPolygonType: action,
      setRoofType: action,
      setRotation: action,
      setShowCompass: action,
      setShowGrid: action,
      setSunPathDate: action,
      toggleOptimizedPolygons: action,
      toggleShowSunlight: action,
      toggleShowTrees: action,
      toggleShowWireframe: action,
      zoomObject: action,
    });
  }

  //OBSERVABLES
  cameraControls = null;
  currentMode = MODES.VIEW_MODE;
  designData = null;
  expandRoofFaces = true;
  footprintType = FOOTPRINT_TYPES.STUCCO;
  geoStorageId = null;
  groundSize = [50, 50];
  groundType = GROUND_TYPES.GRASS;
  hoveredRoofPlane = [];
  index = 0;
  metadata = null;
  playingSunPath = false;
  roofPlanes = [];
  roofPolygonType = ROOF_POLYGON_TYPES.OPTIMIZED_2;
  roofType = ROOF_TYPES.ASPHALT_SHINGLES;
  rotation = 0;
  showCompass = true;
  showGrid = false;
  showOptimizedPolygons = true;
  showInterface = false;
  showSunlight = false;
  showTrees = true;
  showWireframe = false;
  sunPathDate = new Date();

  //ACTIONS
  setter = (observable, guard = () => false) => {
    return newValue => {
      if (guard(newValue)) {
        return;
      }
      this[observable] = newValue;
    };
  };

  toggle = observable => () => (this[observable] = !this[observable]);

  changeMode = this.setter('currentMode', newMode => !MODES[newMode]);

  hoverPlane = roofPlane => {
    if (!roofPlane) {
      this.hoveredRoofPlane = [];
      return;
    }
    this.hoveredRoofPlane = [roofPlane];
  };

  resetCameraControls = () => {
    this.cameraControls.reset();
  };

  setCameraControls = this.setter('cameraControls');

  setDesignData = this.setter('designData');

  setFootprintType = this.setter(
    'footprintType',
    (type = 'BRICK') => !FOOTPRINT_TYPES[type]
  );

  setGeoStorageId = this.setter('geoStorageId');

  setGroundSize = (x, y) => {
    this.groundSize = [x, y];
  };

  setGroundType = this.setter(
    'groundType',
    (type = 'GRASS') => !GROUND_TYPES[type]
  );

  setIndex = this.setter('index');

  setMetadata = this.setter('metadata');

  setPlayingSunPath = this.setter('playingSunPath');

  setRoofPlanes = this.setter('roofPlanes');

  setRoofPolygonType = this.setter(
    'roofPolygonType',
    (type = 'ALPHASHAPE') => !ROOF_POLYGON_TYPES[type]
  );

  setRoofType = this.setter(
    'roofType',
    (type = 'ASPHALT_SHINGLES') => !ROOF_TYPES[type]
  );

  setRotation = this.setter('rotation');

  setShowCompass = this.setter('showCompass');

  setShowGrid = this.setter('showGrid');

  setShowInterface = this.setter('showInterface');

  setSunPathDate = this.setter('sunPathDate');

  toggleExpandRoofFaces = this.toggle('expandRoofFaces');

  toggleOptimizedPolygons = this.toggle('showOptimizedPolygons');

  toggleShowSunlight = this.toggle('showSunlight');

  toggleShowTrees = this.toggle('showTrees');

  toggleShowWireframe = this.toggle('showWireframe');

  zoomObject = object => {
    const { azimuth, tilt } = object;
    const middle = getCenterPoint(object);

    this.resetCameraControls();
    this.cameraControls.target.copy(middle);

    const newAzimuth = -ThreeMath.degToRad(azimuth - 180);
    const newPolarAngle = ThreeMath.degToRad(tilt);
    const ZOOM = 10;
    const original = [
      this.cameraControls.minPolarAngle,
      this.cameraControls.maxPolarAngle,
      this.cameraControls.minAzimuthAngle,
      this.cameraControls.maxAzimuthAngle,
      this.cameraControls.minDistance,
      this.cameraControls.maxDistance,
    ];

    this.cameraControls.minPolarAngle = newPolarAngle;
    this.cameraControls.maxPolarAngle = newPolarAngle;
    this.cameraControls.minAzimuthAngle = newAzimuth;
    this.cameraControls.maxAzimuthAngle = newAzimuth;
    this.cameraControls.minDistance = ZOOM;
    this.cameraControls.maxDistance = ZOOM;
    this.cameraControls.update();

    this.cameraControls.minPolarAngle = original[0];
    this.cameraControls.maxPolarAngle = original[1];
    this.cameraControls.minAzimuthAngle = original[2];
    this.cameraControls.maxAzimuthAngle = original[3];
    this.cameraControls.minDistance = original[4];
    this.cameraControls.maxDistance = original[5];
    this.cameraControls.update();
  };
}

export default new Store();
