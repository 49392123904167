import { useEffect, useMemo, useRef } from 'react';

import { EdgesGeometry } from 'three';

import RoofPlane from './RoofPlane';

import Store, {
  MODES,
  modeGuardedFunction,
  ROOF_POLYGON_TYPES,
} from '../../store/Store';
import { observer } from 'mobx-react-lite';

const SELECTION_MODES = [
  MODES.ROOF_FACE_SELECTION_MODE,
  MODES.SELECTED_ROOF_FACE_MODE,
];
const randomColor = () => {
  const randomBetween = (min, max) =>
    min + Math.floor(Math.random() * (max - min + 1));
  const r = randomBetween(0, 255);
  const g = randomBetween(0, 255);
  const b = randomBetween(0, 255);
  return `rgb(${r},${g},${b})`;
};

const createPolygonObject =
  polygonName =>
  ({
    azimuth,
    color,
    max_production,
    min_production,
    [polygonName]: polygon,
    sunhour_points,
    tilt,
  }) => ({
    azimuth,
    color: color ? `rgb(${color.join()})` : randomColor(),
    max_production,
    min_production,
    plane: polygon?.map(point => {
      return point;
    }),
    sunhour_points,
    tilt,
  });

const roofPolygon = (condition, polygon, currentType, type, roofColor) =>
  condition &&
  polygon.map(
    (
      {
        azimuth,
        color,
        max_production,
        min_production,
        plane,
        solarPanels,
        sunhour_points,
        tilt,
      },
      i
    ) => (
      <RoofPlane
        azimuth={azimuth}
        color={roofColor || color}
        index={i}
        key={i}
        maxProduction={max_production}
        minProduction={min_production}
        plane={plane}
        solarPanels={solarPanels}
        sunhourPoints={sunhour_points}
        tilt={tilt}
        visible={currentType === type}
      />
    )
  );

const Roof = observer(({ roof }) => {
  const roofPlaneGroup = useRef();
  useEffect(() => {
    if (roofPlaneGroup.current) {
      Store.setRoofPlanes(roofPlaneGroup.current.children);
    }
  }, []);

  useEffect(() => {
    if (!SELECTION_MODES.includes(Store.currentMode)) {
      Store.hoverPlane(null);
    }
  }, []);

  const hasPolygons = Boolean(Store?.metadata?.roof_planes?.[0]?.polygon);

  const hasOptimizedPolygons = Boolean(
    Store?.metadata?.roof_planes?.[0]?.optimized_polygon
  );

  const hasOptimizedPolygons2 = Boolean(
    Store?.metadata?.roof_planes?.[0]?.optimized_polygon2
  );

  const planes = hasPolygons && roof?.map(createPolygonObject('polygon'));

  const optimizedPlanes =
    hasOptimizedPolygons && roof?.map(createPolygonObject('optimized_polygon'));

  const optimizedPlanes2 =
    hasOptimizedPolygons2 &&
    roof?.map(createPolygonObject('optimized_polygon2'));

  const onPointerOver = modeGuardedFunction(
    e => {
      const { intersections } = e;
      if (intersections.length) {
        Store.hoverPlane(intersections[0].object);
      }
    },
    Store.currentMode,
    MODES.ROOF_FACE_SELECTION_MODE
  );

  const onPointerOut = modeGuardedFunction(
    () => {
      Store.hoverPlane(null);
    },
    Store.currentMode,
    MODES.ROOF_FACE_SELECTION_MODE
  );

  const focusOnPlane = () => {
    if (Store.hoveredRoofPlane.length) {
      const selectedPlane = Store.hoveredRoofPlane[0];

      Store.zoomObject(selectedPlane);
    }
  };

  const edges = useMemo(
    () =>
      Store.hoveredRoofPlane[0] &&
      new EdgesGeometry(Store.hoveredRoofPlane[0].geometry),
    //eslint-disable-next-line
    [Store.hoveredRoofPlane]
  );

  return (
    <>
      <group
        onClick={focusOnPlane}
        onPointerOut={onPointerOut}
        onPointerOver={onPointerOver}
        ref={roofPlaneGroup}
      >
        {roofPolygon(
          hasOptimizedPolygons,
          optimizedPlanes,
          Store.roofPolygonType,
          ROOF_POLYGON_TYPES.OPTIMIZED_1,
          Store?.metadata?.roof_color &&
            `rgb(${Store.metadata.roof_color.join()})`
        )}
        {roofPolygon(
          hasOptimizedPolygons2,
          optimizedPlanes2,
          Store.roofPolygonType,
          ROOF_POLYGON_TYPES.OPTIMIZED_2,
          Store?.metadata?.roof_color &&
            `rgb(${Store.metadata.roof_color.join()})`
        )}
        {roofPolygon(
          hasPolygons,
          planes,
          Store.roofPolygonType,
          ROOF_POLYGON_TYPES.ALPHASHAPE,
          Store?.metadata?.roof_color &&
            `rgb(${Store.metadata.roof_color.join()})`
        )}
      </group>
      {Store.hoveredRoofPlane[0] && (
        <lineSegments
          geometry={edges}
          position={Store.hoveredRoofPlane[0].position}
          rotation={Store.hoveredRoofPlane[0].rotation}
        >
          <lineBasicMaterial color="red" depthTest={true} lineWidth={5} />
        </lineSegments>
      )}
    </>
  );
});

export default Roof;
