import { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Store from '../../store/Store';

import { useSpring, animated } from 'react-spring/three';
import { useThree } from 'react-three-fiber';

import usePrevious from '../../hooks/usePrevious';

import getDayOfYear from '../../utils/getDayOfYear';

const Sun = observer(() => {
  const {
    metadata: { yearly_sun_vectors: sunPath },
    sunPathDate,
    index,
  } = Store;
  console.log({ sunPath, sunPathDate });
  const sun = useRef();
  if (!sunPath) return null;

  const { scene } = useThree();

  const currentPosition = useMemo(() => {
    const day = getDayOfYear(sunPathDate);
    const hours = [...sunPath]
      .splice(day * 24, 24)
      .map(([x, y, z]) => [x, z, -y]);
    return hours[index];
  }, [sunPath, sunPathDate, index]);

  const prevIndex = usePrevious(index);
  const prevSunPathDate = usePrevious(sunPathDate);

  const duration = useMemo(
    () => (prevSunPathDate !== sunPathDate && prevIndex >= 0 ? 0 : undefined),
    [prevSunPathDate, sunPathDate, prevIndex]
  );

  const { pos, meshPosition } = useSpring({
    pos: currentPosition,
    meshPosition: currentPosition.map(point => point * 30),
    config: { duration },
  });

  const updateLight = useCallback(
    light => {
      light.target.position.set(...currentPosition);
      scene.add(light);
      scene.add(light.target);
    },
    [currentPosition, scene]
  );

  return (
    <>
      <animated.directionalLight
        castShadow
        position={meshPosition}
        ref={sun}
        intensity={currentPosition[1] > 0 ? 1 : 0.05}
        onUpdate={updateLight}
      >
        <animated.mesh position={pos} visible={Store.playingSunPath}>
          <sphereBufferGeometry args={[1, 100, 100]} />
          <meshBasicMaterial color="yellow" />
        </animated.mesh>
      </animated.directionalLight>
    </>
  );
});

export default Sun;
