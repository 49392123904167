import { DoubleSide } from 'three';

import Store from 'store/Store';
import { observer } from 'mobx-react-lite';

import PolygonMesh from 'components/PolygonMesh';

const Wallygon = observer(({ indices, points }) => {
  return (
    <PolygonMesh
      points={points}
      receiveShadow
      castShadow
      indices={indices}
      rotation={[-Math.PI / 2, 0, 0]}
      log={'WALLYGON'}
      wireframe={Store.showWireframe}
    >
      <meshStandardMaterial color="grey" side={DoubleSide} />
    </PolygonMesh>
  );
});

export default Wallygon;
