import { Suspense, useEffect } from 'react';

import { Canvas } from 'react-three-fiber';

import { sRGBEncoding, NoToneMapping } from 'three';

import Controls from '../Controls';
import Ground from '../Ground';
import Helpers from '../Helpers';
import House from '../House';
import Lighting from '../Lighting';
import OldGround from '../Ground/OldGround';
import Toolbar from '../Toolbar';
import Toolbox from '../Toolbox';
import Trees from '../Trees';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import { useHistory, useParams } from 'react-router-dom';

import fetchGeoStorageMetadata from '../../services/fetchGeoStorageMetadata';
import fetchAutomatedDesign from '../../services/fetchAutomatedDesign';
import usePrevious from '../../hooks/usePrevious';

const Scene = observer(() => {
  const history = useHistory();
  const { storageId, automatedDesignId } = useParams();

  const previousStorageId = usePrevious(storageId);
  const previousAutomatedDesignId = usePrevious(automatedDesignId);

  useEffect(() => {
    const getMetadataAndOrDesignData = async () => {
      const isDesignRoute = history.location.pathname.includes('design');
      const refreshCheck = (() => {
        if (isDesignRoute) {
          return (
            !Store.metadata ||
            !Store.designData ||
            previousAutomatedDesignId !== automatedDesignId
          );
        }
        return !Store.metadata || previousStorageId !== storageId;
      })();
      if (refreshCheck) {
        let geoStorageId = storageId;
        if (isDesignRoute) {
          const {
            geoStorage: { storageId: designStorageId },
            design_data,
          } = await fetchAutomatedDesign(automatedDesignId);
          Store.setDesignData(design_data);
          geoStorageId = designStorageId;
        }
        Store.setGeoStorageId(geoStorageId);
        const metadata = await fetchGeoStorageMetadata(geoStorageId);
        if (!metadata) {
          history.push('/');
        }
        Store.setMetadata(metadata);
      }
    };
    getMetadataAndOrDesignData();
  }, [
    history,
    previousStorageId,
    storageId,
    automatedDesignId,
    previousAutomatedDesignId,
  ]);

  const trees = Store?.metadata?.trees;

  if (!Store.metadata) {
    return <></>;
  }

  return (
    <>
      <Toolbar />
      <Toolbox />
      <Helpers />
      <Canvas
        concurrent
        shadowMap
        style={{
          background: '#303030',
          overflow: 'hidden',
          paddingTop: 32,
          width: Store.showInterface ? 'calc(100vw - 275px)' : 'inherit',
          position: 'relative',
        }}
        camera={{ position: [0, 50, 0] }}
        gl={{
          antialias: true,
          outputEncoding: sRGBEncoding,
          toneMapping: NoToneMapping,
          gammaOutput: true,
        }}
      >
        <Lighting />
        <Controls />
        {Store.showGrid && (
          <gridHelper
            args={[
              Store?.metadata?.texture_scaling?.perimeter,
              Store?.metadata?.texture_scaling?.perimeter,
              `#B81B06`,
              `#5f5f5f`,
            ]}
            position={[0, 0.02, 0]}
          />
        )}
        <Suspense fallback={null}>
          {Store.metadata.texture_scaling ? <Ground /> : <OldGround />}
        </Suspense>
        <Suspense fallback={null}>
          <House />
        </Suspense>
        <Suspense fallback={null}>
          {Store.showTrees && <Trees trees={trees} />}
        </Suspense>
      </Canvas>
    </>
  );
});

export default Scene;
