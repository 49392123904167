import axios from 'axios';

import demo1 from '../assets/Demos/1/PointCloud_Metadata.json';
import demo2 from '../assets/Demos/2/PointCloud_Metadata.json';

const fetchGeoStorageMetadata = async storageId => {
  if (storageId === 'demo-1') {
    return demo1;
  }
  if (storageId === 'demo-2') {
    return demo2;
  }
  try {
    const { data: metadata } = await axios.get(
      `https://api.gosolo.io/api/v1/geo-storage/geomni/${storageId}/metadata`
    );
    return metadata;
  } catch (e) {
    return null;
  }
};

export default fetchGeoStorageMetadata;
