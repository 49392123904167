import { useEffect, useRef } from 'react';

import { useFrame, useThree } from 'react-three-fiber';
import { OrbitControls } from 'drei';
import { Vector3, Spherical, Math as ThreeMath } from 'three';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

function Controls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const { rotation, setRotation } = Store;

  const controls = useRef();

  useEffect(() => {
    if (controls.current) Store.setCameraControls(controls.current);
  }, [controls]);

  const dir = new Vector3();
  const sph = new Spherical();
  useFrame(() => {
    if (controls.current) {
      controls.current.update();
    }
    camera.getWorldDirection(dir);
    sph.setFromVector3(dir);
    const newRotation = ThreeMath.radToDeg(sph.theta) - 180;
    if (rotation !== newRotation) {
      setRotation(newRotation);
    }
  });

  return (
    <>
      <OrbitControls
        ref={controls}
        args={[camera, domElement]}
        enableDamping={false}
        screenSpacePanning
        polarAngle={1}
        maxPolarAngle={Math.PI / 2}
        maxDistance={50}
        minDistance={0}
        enableKeys={true}
      />
    </>
  );
}

export default observer(Controls);
