import { useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Box3 } from 'three';

const limit5 = option => Math.min(option, 5);

const getScale = ({ x, y, z }, height, radius) => [
  limit5((x * (radius / 2 / x)) / 2),
  (height / y) * 1.5,
  limit5((z * (radius / 2 / z)) / 2),
];

const Trees = ({ trees }) => {
  const { scene: treeModel } = useLoader(GLTFLoader, '/tree.glb');
  treeModel.traverse(child => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
  const { max } = new Box3().setFromObject(treeModel);

  return (
    <>
      {trees.map(({ height, position, radius }) => {
        const [x, y] = position;
        return (
          <primitive
            dispose={null}
            object={treeModel.clone()}
            position={[x, 0, -y]}
            scale={getScale(max, height, radius)}
          />
        );
      })}
    </>
  );
};

export default Trees;
