import { useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import HelperButton from './HelperButton';

import find from 'lodash/find';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import Store, { ROOF_POLYGON_TYPES } from 'store/Store';
import { observer } from 'mobx-react-lite';

import useBreakpoints from '../../hooks/useBreakpoints';

const roofPolygonOptions = map(ROOF_POLYGON_TYPES, (type: string) => ({
  label: startCase(type),
  value: type,
  icon: type[0] === 'O' ? `${type[0]}${type[type.length - 1]}` : type[0],
}));

interface Props {}

const Polygons: React.FC<Props> = observer(() => {
  const { lgUp }: any = useBreakpoints('lgUp');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectRoofPolygonType = (type: string) => {
    Store.setRoofPolygonType(type);
    handleClose();
  };

  const selectedRoofPolygonType = find(roofPolygonOptions, {
    value: Store.roofPolygonType,
  });

  return (
    <>
      <HelperButton
        onClick={handleClick}
        icon={lgUp ? null : selectedRoofPolygonType?.icon}
      >
        {selectedRoofPolygonType?.label}
      </HelperButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {roofPolygonOptions.map(
          ({ label, value }: { label: string; value: string }) => (
            <MenuItem key={value} onClick={() => selectRoofPolygonType(value)}>
              <Typography variant="h6">{label}</Typography>
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
});

export default Polygons;
