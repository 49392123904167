import { useRef } from 'react';
import FootPrint from './FootPrint';
import Panels from './Panels';
import Roof from './Roof';
import Wallygon from './Wallygon';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

const House = observer(() => {
  const group = useRef();
  const footprintPoints = Store.metadata.building_footprint_coordinates[0].map(
    ([x, y]) => [x, y]
  );
  const hasWallygons = Store?.metadata?.wallygons;
  const wallygons = Store?.metadata?.wallygons?.map(wallygon =>
    wallygon.map(([x, y, z]) => [x, y, z])
  );
  const earcutIndices = Store?.metadata?.wallygon_earcut_indices;
  return (
    <>
      <group ref={group}>
        <Roof roof={Store.metadata.roof_planes} />
        {!Store.showWireframe && (
          <FootPrint points={footprintPoints} depth={0.1} />
        )}
        <Panels />
        {hasWallygons &&
          wallygons?.map((points, i) => (
            <Wallygon
              points={points}
              indices={earcutIndices && earcutIndices[i]}
            />
          ))}
      </group>
    </>
  );
});

export default House;
