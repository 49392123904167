import { useEffect, useMemo } from 'react';
import { DoubleSide, RepeatWrapping, Shape, Vector2 } from 'three';

import { useLoader } from 'react-three-fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import brickTexture from '../../assets/Textures/Footprint/Brick/Color.jpg';
import stuccoTexture from '../../assets/Textures/Footprint/Stucco/Color.jpg';

const defaultShape = [
  [10, 10],
  [10, -10],
  [-10, -10],
  [-10, 10],
];

const FOOTPRINT_TYPES = {
  BRICK: brickTexture,
  STUCCO: stuccoTexture,
};

const FootPrint = observer(
  ({ points = defaultShape, depth = 1, scalar = 1 }) => {
    const texture = useLoader(
      TextureLoader,
      FOOTPRINT_TYPES[Store.footprintType]
    );
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;

    const shapePoints = points.map(
      Store.metadata['demo-2']
        ? ([x, y]) => new Vector2(...[(x + 0.8) * scalar, (y + 3.75) * scalar])
        : ([x, y]) => new Vector2(...[x * scalar, y * scalar])
    );

    const minPerimeter = useMemo(() => {
      return (
        Math.round(
          Math.ceil(
            Math.max(
              ...shapePoints.flatMap(({ x, y }) => {
                if (x < 0) {
                  x *= -1;
                }
                if (y < 0) {
                  y *= -1;
                }
                return [x * 2, y * 2];
              })
            )
          ) / 10
        ) *
          10 +
        20
      );
    }, [shapePoints]);

    useEffect(() => {
      if (minPerimeter > 50) {
        Store.setGroundSize(minPerimeter, minPerimeter);
      } else {
        Store.setGroundSize(50, 50);
      }
    }, [minPerimeter]);

    return (
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.1, 0]} castShadow>
        <extrudeGeometry
          args={[
            new Shape(shapePoints),
            {
              depth,
              bevelEnabled: false,
            },
          ]}
        />
        <meshStandardMaterial color="grey" side={DoubleSide} />
      </mesh>
    );
  }
);

export default FootPrint;
