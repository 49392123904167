import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

const ModeWrapper = observer(({ children, modes }) => {
  if (!modes.includes(Store.currentMode) && modes !== Store.currentMode) {
    return null;
  }
  return <>{children}</>;
});

export default ModeWrapper;
