import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import numeral from 'numeral';

import Store, { MODES, modeGuardedFunction } from '../../store/Store';
import { observer } from 'mobx-react-lite';

import ModeWrapper from '../ModeWrapper';

const SELECTION_MODES = [
  MODES.ROOF_FACE_SELECTION_MODE,
  MODES.SELECTED_ROOF_FACE_MODE,
];

const useStyles = makeStyles(theme => ({
  button: { borderRadius: 0 },
  buttonContainer: {
    padding: theme.spacing(1),
  },
  disabled: {
    opacity: 0.3,
  },
  selectButton: {
    color: '#0060E0',
    cursor: 'pointer',
    '&:hover': {
      color: '#005393',
    },
    fontFamily: theme.typography.button.fontFamily,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  table: {
    '& th': {
      padding: theme.spacing(1),
      '& p': {
        fontWeight: 900,
      },
    },
    '& td': {
      padding: theme.spacing(1),
    },
  },
}));

const columnNames = ['Face', 'Azimuth', 'Tilt'];

const RoofFaceTable = observer(() => {
  const classes = useStyles();
  const handleMouseOver = index =>
    modeGuardedFunction(
      () => Store.hoverPlane(Store.roofPlanes[index]),
      Store.currentMode,
      MODES.ROOF_FACE_SELECTION_MODE
    );
  const handleMouseOut = modeGuardedFunction(
    () => Store.hoverPlane(null),
    Store.currentMode,
    MODES.ROOF_FACE_SELECTION_MODE
  );

  const handleSelect = index =>
    modeGuardedFunction(
      () => Store.zoomObject(Store.roofPlanes[index]),
      Store.currentMode,
      MODES.ROOF_FACE_SELECTION_MODE
    );

  const selectionMode = SELECTION_MODES.includes(Store.currentMode);

  const handleClick = () => {
    const newMode = selectionMode
      ? MODES.VIEW_MODE
      : MODES.ROOF_FACE_SELECTION_MODE;
    Store.changeMode(newMode);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ borderTop: '1px solid #e0e0e0' }}>
              {columnNames.map(name => (
                <TableCell padding="none">
                  <Typography variant="body1">{name}</Typography>
                </TableCell>
              ))}
              <ModeWrapper modes={SELECTION_MODES}>
                <TableCell padding="none" />
              </ModeWrapper>
            </TableRow>
          </TableHead>
          <TableBody>
            {Store?.metadata?.roof_planes?.map(({ azimuth, tilt }, index) => (
              <TableRow
                hover
                onMouseOver={handleMouseOver(index)}
                onMouseOut={handleMouseOut}
              >
                <TableCell padding="none">{index + 1}</TableCell>
                <TableCell padding="none">
                  {numeral(azimuth).format('0,0')}
                </TableCell>
                <TableCell padding="none">
                  {numeral(tilt).format('0,0')}
                </TableCell>
                <ModeWrapper modes={SELECTION_MODES}>
                  <TableCell padding="none">
                    <Button
                      className={classes.button}
                      fullWidth
                      onClick={handleSelect(index)}
                    >
                      Select
                    </Button>
                  </TableCell>
                </ModeWrapper>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid
        alignItems="center"
        className={classes.buttonContainer}
        container
        item
        xs={12}
      >
        <Grid item xs={6}>
          <Typography
            align="center"
            className={`${classes.selectButton}${
              selectionMode ? ` ${classes.disabled}` : ''
            }`}
            disabled={selectionMode}
            onClick={handleClick}
          >
            Select Roof Face
          </Typography>
        </Grid>
        <ModeWrapper modes={SELECTION_MODES}>
          <Grid item xs={6}>
            <Typography
              align="center"
              className={classes.selectButton}
              fullWidth
              onClick={handleClick}
            >
              Exit
            </Typography>
          </Grid>
        </ModeWrapper>
      </Grid>
    </Grid>
  );
});

export default RoofFaceTable;
