import { useState } from 'react';

import LinkIcon from '@material-ui/icons/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import HelperButton from './HelperButton';

import Store from 'store/Store';
import { observer } from 'mobx-react-lite';

const demos = {
  'demo-1': '_t243dtEK',
  'demo-2': 'PLt2f1eWI',
};

const links = [
  {
    category: 'Geomni',
    subLinks: [
      { link: 'PointCloud_Gradients.ply' },
      { link: 'metadata.json' },
      { link: 'Standard_Ortho_RGB.jpeg' },
      { link: 'pointcloud.npz' },
    ],
  },
  {
    category: 'Solo Processed',
    subLinks: [
      { link: 'PointCloud_RoofPlanes.ply' },
      { link: 'PointCloud_SunHours.ply' },
      { link: 'PointCloud_Metadata.json' },
    ],
  },
];

const useStyles = makeStyles(theme => ({
  category: {
    marginLeft: theme.spacing(1),
  },
}));

const Links = observer(() => {
  const classes = useStyles();
  const { geoStorageId: storageId } = Store;

  const geoStorageId =
    storageId === 'demo-1' || storageId === 'demo-2'
      ? demos[storageId]
      : storageId;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <HelperButton icon={<LinkIcon />} onClick={handleClick}>
        Links
      </HelperButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {links.map(({ category, subLinks }) => (
          <div key={category}>
            <Typography className={classes.category} variant="h6">
              {category.toUpperCase()}
            </Typography>
            {subLinks.map(({ label, link }) => (
              <MenuItem key={label}>
                <Typography
                  component="a"
                  href={`https://storage.googleapis.com/geo-storage/${geoStorageId}/${link}`}
                  target="_blank"
                  variant="h6"
                >
                  {link}
                </Typography>
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </>
  );
});

export default Links;
