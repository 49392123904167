import { Plane } from 'drei';
import { useLoader } from 'react-three-fiber';
import { DoubleSide, RepeatWrapping, TextureLoader } from 'three';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import groundTexture from 'assets/Textures/Ground/Grass/Color.jpg';

const Ground = observer(() => {
  const texture = useLoader(TextureLoader, groundTexture);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.repeat.set(5, 5);
  return (
    <>
      <Plane
        args={Store.groundSize}
        position={[0, 0.01, -0.5]}
        rotation={[-Math.PI / 2, 0, 0]}
        receiveShadow
      >
        <meshLambertMaterial color="grey" map={texture} side={DoubleSide} />
      </Plane>
    </>
  );
});

export default Ground;
