import { useEffect, useMemo, useRef, useState } from 'react';
import { EdgesGeometry, Math as ThreeMath, Matrix4, Vector3 } from 'three';

const ORIENTATIONS = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
};

const Panel = ({
  width = 1,
  thickness = 0.035,
  length = 2,
  tilt,
  azimuth,
  orientation = ORIENTATIONS.PORTRAIT,
  ...props
}) => {
  const [scale, setScale] = useState([0, 0, 0]);
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current) {
      const azimuthTransformationMatrix = new Matrix4();
      const tiltTransformationMatrix = new Matrix4();
      azimuthTransformationMatrix.makeRotationAxis(
        new Vector3(0, 1, 0),
        ThreeMath.degToRad(-azimuth)
      );
      tiltTransformationMatrix.makeRotationAxis(
        new Vector3(1, 0, 0),
        ThreeMath.degToRad(-tilt)
      );
      tiltTransformationMatrix.premultiply(azimuthTransformationMatrix);
      ref.current.setRotationFromMatrix(tiltTransformationMatrix);
      setScale([1, 1, 1]);
    }
  }, [ref, azimuth, tilt]);

  const edges = useMemo(
    () => ref.current && new EdgesGeometry(ref?.current?.geometry),
    //eslint-disable-next-line
    [ref?.current?.geometry]
  );

  const dimensions =
    orientation === ORIENTATIONS.PORTRAIT
      ? [width, thickness + 0.05, length]
      : [length, thickness + 0.05, width];

  return (
    <>
      <mesh {...props} ref={ref} scale={scale}>
        <boxBufferGeometry args={dimensions} />
        <meshStandardMaterial color="#111133" />
      </mesh>
      {edges && (
        <lineSegments
          geometry={edges}
          position={ref.current.position}
          rotation={ref.current.rotation}
        >
          <lineBasicMaterial color="white" depthTest={true} lineWidth={1} />
        </lineSegments>
      )}
    </>
  );
};

export default Panel;
