import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import AutomatedDesignSearch from 'components/AutomatedDesignSearch';
import StorageIdSearch from 'components/StorageIdSearch';

const useStyles = makeStyles(theme => ({
  button: {
    height: 56,
  },
  form: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 480,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    // width: '100%',
  },
}));

const Home = () => {
  const classes = useStyles();

  //PLt2f1eWI
  //_t243dtEK

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <StorageIdSearch />
      </Paper>
      <Paper className={classes.paper}>
        <AutomatedDesignSearch />
      </Paper>
    </div>
  );
};

export default Home;
