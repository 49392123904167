import { useRef, useEffect } from 'react';

import ExploreIcon from '@material-ui/icons/Explore';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(theme => ({
  compass: {
    fill: '#fff',
    fontSize: theme.spacing(6) - 6,
    transform: 'rotate(-45deg)',
  },
  north: {
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    position: 'absolute',
    top: -14,
    userSelect: 'none',
  },
  root: {
    color: '#fff',
    cursor: 'pointer',
    padding: 0,
    position: 'absolute',
    top: 12,
  },
}));

const Compass = observer(() => {
  const classes = useStyles();
  const ref = useRef();

  const { rotation } = Store;

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `rotate(${rotation}deg)`;
    }
  }, [rotation]);

  return (
    <>
      <Tooltip placement="left" title="RESET CAMERA">
        <div
          ref={ref}
          className={classes.root}
          onClick={Store.resetCameraControls}
        >
          <div>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ position: 'relative' }}
            >
              <Typography className={classes.north}>N</Typography>
              <ExploreIcon className={classes.compass} />
            </Grid>
          </div>
        </div>
      </Tooltip>
    </>
  );
});

export default Compass;
