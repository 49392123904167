import * as THREE from 'three';
import { extend } from 'react-three-fiber';

const defaultShader = `void main() {}`;
export class IrradianceMapMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        sunhourPoints: { value: [new THREE.Vector3(0, 0, 0)] },
        sunhourRGB: { value: [new THREE.Vector3(0, 0, 0)] },
      },
      vertexShader: defaultShader,
      fragmentShader: defaultShader,
    });
  }
  get shaderUniforms() {
    return this.uniforms;
  }
  get sunhourPoints() {
    return this.uniforms.sunhourPoints.value;
  }
  get sunHourRGBA() {
    return this.uniforms.sunHourRGB.value;
  }
  set shaderUniforms({ sunhourPoints }) {
    this.uniforms.sunhourPoints.value = sunhourPoints.map(
      ([x, y, z]) => new THREE.Vector3(x, y, z)
    );
    this.uniforms.sunhourRGB.value = sunhourPoints.map(
      ([x, y, z, r, g, b]) => new THREE.Vector3(r / 255, g / 255, b / 255)
    );

    this.vertexShader = /*glsl*/ `
      varying vec4 worldCoord;

      void main() {

        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;

        vec4 worldCoord = modelMatrix * vec4( position, 1.0 );
      }
    `;
    this.fragmentShader = /*glsl*/ `
    #define SUNHOUR_POINTS_LENGTH ${sunhourPoints.length}

    uniform vec4[SUNHOUR_POINTS_LENGTH] sunhourPoints;
    uniform vec4[SUNHOUR_POINTS_LENGTH] sunhourRGB;

    varying vec4 worldCoord;

    void main() {
      const float eps = 0.000000000000001;
      vec3 rgb = vec3(0.5, 0.5, 0.5);

      for (int i=0; i < SUNHOUR_POINTS_LENGTH; i++) {
        if(
            abs(sunhourPoints[i].x - worldCoord.x) > eps &&
            abs(sunhourPoints[i].y - worldCoord.y) > eps
            // abs(sunhourPoints[i].z - worldCoord.z) > eps
          ) {
          rgb = vec3(sunhourRGB[i].x, sunhourRGB[i].y, sunhourRGB[i].z);
        }
      }

      gl_FragColor = vec4(rgb.x, rgb.y, rgb.z, 1.0);
    }
    `;
  }
}

extend({ IrradianceMapMaterial });
