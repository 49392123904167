import React from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import useBreakpoints from '../../hooks/useBreakpoints';

const useStyles = makeStyles(theme => ({
  button: {
    width: 220,
  },
  children: {
    marginRight: 12,
  },
  spacer: {
    marginBottom: 16,
  },
  tooltip: {
    textTransform: 'uppercase',
  },
}));

interface Props {
  children?: React.ReactChild;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  icon?: React.ReactChild | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HelperButton: React.FC<Props> = ({
  children,
  color = 'primary',
  icon,
  onClick = () => {},
}) => {
  const classes = useStyles();
  const { lgUp }: any = useBreakpoints('lgUp');
  return lgUp ? (
    <Button
      className={`${classes.button} ${classes.spacer}`}
      color={color}
      onClick={onClick}
      size="medium"
      variant="contained"
    >
      <div className={classes.children}>{children}</div>
      {icon}
    </Button>
  ) : (
    <Tooltip
      placement="left"
      title={<Typography className={classes.tooltip}>{children}</Typography>}
    >
      <Fab
        color={color}
        onClick={onClick}
        size="medium"
        className={classes.spacer}
      >
        {icon || <></>}
      </Fab>
    </Tooltip>
  );
};

export default HelperButton;
