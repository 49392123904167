import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import Panel from 'components/Panel';

const Panels = observer(() => {
  const { designData } = Store;
  if (!designData || !designData.roof_faces) {
    return null;
  }
  const { panel: { width, length } = null, roof_faces } = designData;
  const panels = Object.keys(roof_faces).reduce((panelArray, key) => {
    if (!isNaN(Number(key))) {
      const { azimuth, panel_center_points, tilt } = roof_faces[key];
      panelArray = [
        ...panelArray,
        ...panel_center_points.map(([x, y, z]) => ({
          azimuth,
          length,
          position: [x, z, -y],
          tilt,
          width,
        })),
      ];
    }
    return panelArray;
  }, []);
  return (
    <>
      {panels.map((props, i) => (
        <group>
          <Panel key={i} {...props} />
        </group>
      ))}
    </>
  );
});

export default Panels;
