import React, { useCallback } from 'react';

import { Earcut } from 'three/src/extras/Earcut';

import createUvMap from 'utils/createUVMap';

const MeshWrapper = ({ children, wireframe, ...props }) =>
  wireframe ? (
    <line {...props}>{children}</line>
  ) : (
    <mesh {...props}>{children}</mesh>
  );

const PolygonMesh = React.forwardRef(
  ({ children, indices, points, wireframe, ...props }, ref) => {
    const update = useCallback(self => {
      self.parent.computeBoundingSphere();
      self.parent.computeBoundingBox();
      self.parent.computeVertexNormals();
      self.parent.computeFaceNormals();
      createUvMap(self.parent);
    }, []);
    if (!points) return null;
    const triangles = indices || Earcut.triangulate(points.flat(), null, 3);
    const trianglePoints = triangles.map(
      trianglePoint => points[trianglePoint]
    );

    const renderPoints = wireframe ? points : trianglePoints;

    return (
      <MeshWrapper {...props} ref={ref} wireframe={wireframe}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attachObject={['attributes', 'position']}
            array={new Float32Array(renderPoints.flat())}
            count={renderPoints.flat().length / 3}
            itemSize={3}
            onUpdate={update}
          />
        </bufferGeometry>
        {children}
      </MeshWrapper>
    );
  }
);

export default PolygonMesh;
