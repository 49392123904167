import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import SearchInput from 'components/SearchInput';

import fetchGeoStorageMetadata from '../../services/fetchGeoStorageMetadata';
import fetchAutomatedDesign from '../../services/fetchAutomatedDesign';

const AutomatedPropSearch: React.FC = observer(() => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const onSubmit = async ({ automatedDesignId }: any) => {
    if (!automatedDesignId) {
      setNotFound(false);
      return;
    }
    setNotFound(false);
    setSubmitting(true);
    const {
      geoStorage: { storageId },
      design_data,
    }: any = await fetchAutomatedDesign(automatedDesignId);
    if (!storageId || !design_data) {
      setNotFound(true);
      setSubmitting(false);
      return;
    }
    Store.setDesignData(design_data);
    Store.setGeoStorageId(storageId);
    const metadata = await fetchGeoStorageMetadata(storageId);
    if (metadata) {
      Store.setMetadata(metadata);
      history.push(`/design/${automatedDesignId}`);
      return;
    }
    setSubmitting(false);
    setNotFound(true);
  };

  return (
    <SearchInput
      error={notFound}
      helperText={notFound ? 'No automated design found' : ''}
      name="automatedDesignId"
      onSubmit={onSubmit}
      placeholder="Automated Design Id"
      submitting={submitting}
    />
  );
});

export default AutomatedPropSearch;
