import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUIToolbar from '@material-ui/core/Toolbar';

import Compass from './Compass';

const useStyles = makeStyles(theme => ({
  appBar: {
    height: 64,
    backgroundColor: '#000',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    padding: '0 16px'
  }
}));

const Toolbar = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <MUIToolbar className={classes.toolbar}>
        <Compass />
        <Grid alignItems="center" spacing={1} container justify="flex-end">
          <Grid item>
            <div style={{ backgroundColor: '#fff', borderRadius: 4 }}>
              <Button disabled variant="contained">
                Exit
              </Button>
            </div>
          </Grid>
        </Grid>
      </MUIToolbar>
    </AppBar>
  );
};

export default Toolbar;
