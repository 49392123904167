import useMediaQuery from '@material-ui/core/useMediaQuery';

const useBreakpoints = (...args) => {
  // ACCEPTABLE ARGUMENTS AND THEIR VALUES
  //  xsUp = useMediaQuery(theme => theme.breakpoints.up('xs'));
  //  smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  //  mdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  //  lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));
  //  xlUp = useMediaQuery(theme => theme.breakpoints.up('xl'));

  //  xlDown = useMediaQuery(theme => theme.breakpoints.down('xl'));
  //  lgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  //  mdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  //  smDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  //  xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));

  //  xsOnly = useMediaQuery(theme => theme.breakpoints.only('xs'));
  //  smOnly = useMediaQuery(theme => theme.breakpoints.only('sm'));
  //  mdOnly = useMediaQuery(theme => theme.breakpoints.only('md'));
  //  lgOnly = useMediaQuery(theme => theme.breakpoints.only('lg'));
  //  xlOnly = useMediaQuery(theme => theme.breakpoints.only('xl'));

  //  xsToSm = useMediaQuery(theme => theme.breakpoints.between('xs', 'sm'));
  //  xsToMd = useMediaQuery(theme => theme.breakpoints.between('xs', 'sm'));
  //  xsToLg = useMediaQuery(theme => theme.breakpoints.between('xs', 'lg'));
  //  smToMd = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
  //  smToLg = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  //  smToXl = useMediaQuery(theme => theme.breakpoints.between('sm', 'xl'));
  //  mdToLg = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
  //  mdToXl = useMediaQuery(theme => theme.breakpoints.between('md', 'xl'));
  //  lgToXl = useMediaQuery(theme => theme.breakpoints.between('lg', 'xl'));
  const breakpoints = {};

  args.forEach(arg => {
    const direction = arg.slice(2).toLowerCase();
    const between = arg.slice(2, 4).toLowerCase() === 'to';
    const firstBreakpoint = arg.slice(0, 2).toLowerCase();
    const secondBreakpoint = arg.slice(4).toLowerCase();
    // Ensures that arguments match all possible breakpoint strings
    const match = string => Boolean(string.match(/^(xs|sm|md|lg|xl)$/));

    if (direction === 'up' && match(firstBreakpoint)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      breakpoints[arg] = useMediaQuery(theme =>
        theme.breakpoints.up(firstBreakpoint)
      );
    }
    if (direction === 'down' && match(firstBreakpoint)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      breakpoints[arg] = useMediaQuery(theme =>
        theme.breakpoints.down(firstBreakpoint)
      );
    }
    if (direction === 'only' && match(firstBreakpoint)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      breakpoints[arg] = useMediaQuery(theme =>
        theme.breakpoints.only(firstBreakpoint)
      );
    }
    if (between && match(firstBreakpoint) && match(secondBreakpoint)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      breakpoints[arg] = useMediaQuery(theme =>
        theme.breakpoints.between(firstBreakpoint, secondBreakpoint)
      );
    }
  });

  return breakpoints;
};

export default useBreakpoints;
