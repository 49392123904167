import Scene from '../views/Scene';
import Home from '../views/Home';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/:storageId',
    component: Scene,
    exact: true,
  },
  {
    path: '/design/:automatedDesignId',
    component: Scene,
    exact: true,
  },
];

export default routes;
