import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import Sun from './Sun';

const Lighting = observer(() => {
  return (
    <>
      <ambientLight intensity={0.25} />
      {!Store.playingSunPath && (
        <directionalLight castShadow position={[0, 5, 0]} intensity={1} />
      )}
      <Sun />
    </>
  );
});

export default Lighting;
