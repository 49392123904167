import { useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import BuildIcon from '@material-ui/icons/Build';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
// import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
// import TextureSharpIcon from '@material-ui/icons/TextureSharp';
import Typography from '@material-ui/core/Typography';

import RoofFaceTable from './RoofFaceTable';
import Sunpath from './Sunpath';
// import Textures from './Textures';

import Store, { MODES } from '../../store/Store';
import { observer } from 'mobx-react-lite';

const SELECTION_MODES = [
  MODES.ROOF_FACE_SELECTION_MODE,
  MODES.SELECTED_ROOF_FACE_MODE,
];

const useStyles = makeStyles(theme => ({
  button: {
    color: '#0060E0',
    cursor: 'pointer',
    '&:hover': {
      color: '#005393',
    },
    fontFamily: theme.typography.button.fontFamily,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  buttonContainer: {
    padding: theme.spacing(1),
  },
  disabled: {
    opacity: 0.3,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    borderBottom: 'solid 1px #000',
    paddingLeft: theme.spacing(1),
    color: '#000',
  },
  root: {
    borderRadius: 0,
    left: 16,
    position: 'absolute',
    top: 80,
    width: 325,
    zIndex: 300,
  },
  tabButtons: {
    flex: 1,
    fontSize: 10,
    padding: '8px 0 0 0',
    minWidth: 'inherit',
    minHeight: 'inherit',
  },
}));

const tabs = [
  {
    component: <RoofFaceTable />,
    icon: <HomeOutlinedIcon fontSize="small" />,
    label: 'Roof Faces',
    value: 'roof-faces',
  },
  // {
  //   component: <Textures />,
  //   icon: (
  //     <>
  //       <div style={{ margin: 0 }}>
  //         <TextureSharpIcon style={{ position: 'absolute' }} fontSize="small" />{' '}
  //         <HomeIcon fontSize="small" />
  //       </div>
  //     </>
  //   ),
  //   label: 'Textures',
  //   value: 'textures',
  // },
  {
    component: <Sunpath />,
    icon: <Brightness6Icon fontSize="small" />,
    label: 'Sun Path',
    value: 'sun-path',
  },
];

const Toolbox = observer(() => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('roof-faces');

  const selectionMode = SELECTION_MODES.includes(Store.currentMode);

  useEffect(() => {
    if (currentTab !== 'roof-faces' && selectionMode) {
      Store.changeMode(MODES.VIEW_MODE);
    }
    if (currentTab !== 'sun-path' && Store.playingSunPath) {
      Store.setPlayingSunPath(false);
    }
  }, [currentTab, selectionMode]);

  const currentComponent = tabs.find(
    ({ value }) => value === currentTab
  ).component;

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Grid
        alignItems="center"
        className={classes.header}
        container
        justify="space-between"
      >
        <Grid alignItems="center" container item xs={6}>
          <Grid item>
            <BuildIcon fontSize="small" style={{ margin: '4px 4px 0 0' }} />
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 900 }}>
              Toolbox
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            onClick={Store.toggleExpandRoofFaces}
            style={{ color: '#000' }}
          >
            {Store.expandRoofFaces ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <AddCircleOutlineIcon />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={Store.expandRoofFaces}>
        <Tabs
          indicatorColor="primary"
          onChange={handleChangeTab}
          value={currentTab}
          style={{ borderBottom: '1px solid #e0e0e0' }}
        >
          {tabs.map(({ icon, label, value }) => (
            <Tab
              classes={{ root: classes.tabButtons }}
              disabled={
                selectionMode || (Store.playingSunPath && Store.index < 23)
              }
              icon={icon}
              label={label}
              value={value}
            ></Tab>
          ))}
        </Tabs>
        {currentComponent}
      </Collapse>
    </Paper>
  );
});

export default Toolbox;
