import { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Calendar, KeyboardDatePicker } from '@material-ui/pickers';

import { set } from 'date-fns';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(theme => ({
  button: {
    height: 53.63,
  },
  root: {
    overflow: 'hidden',
    paddingBottom: 8,
    width: '100%',
  },
}));

const Sunpath = observer(() => {
  const classes = useStyles();

  const handleDateChange = date => {
    //CHECK IF VALID DATE
    if (date instanceof Date && isFinite(date)) {
      Store.setSunPathDate(date);
    }
  };

  useEffect(() => {
    const play = async () => {
      if (Store.index < 23) {
        Store.setIndex(Store.index + 1);
      }
    };
    const id = setInterval(play, 200);
    if (!Store.playingSunPath || Store.index === 23) {
      clearInterval(id);
      return;
    }
    return () => {
      clearInterval(id);
      Store.setIndex(0);
    };
    //eslint-disable-next-line
  }, [Store.sunPathDate, Store.playingSunPath]);

  const togglePlay = async () => {
    Store.setPlayingSunPath(!Store.playingSunPath);
  };

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      justify="center"
    >
      <Grid item xs={12}>
        <Calendar
          date={Store.sunPathDate}
          minDate={set(new Date(), { month: 0, date: 1 })}
          maxDate={set(new Date(), { month: 11, date: 31 })}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid
        alignItems="center"
        container
        item
        justify="space-evenly"
        spacing={2}
        xs={12}
      >
        <Grid item xs={6}>
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            inputProps={{ style: { textAlign: 'center' } }}
            style={{ textAlign: 'center' }}
            format="MM/dd"
            value={Store.sunPathDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
          />
        </Grid>
        <Grid align="center" container item justify="center" xs={6}>
          <Grid item xs={6}>
            <Tooltip
              title={
                Store.playingSunPath ? (
                  <Typography>Stop</Typography>
                ) : (
                  <Typography>Play</Typography>
                )
              }
            >
              <Button
                className={classes.button}
                color="primary"
                onClick={togglePlay}
                variant="contained"
                size="small"
              >
                {Store.playingSunPath ? <StopIcon /> : <PlayArrowIcon />}
              </Button>
            </Tooltip>
          </Grid>
          {Store.playingSunPath && (
            <Grid item xs={6}>
              <Tooltip title={<Typography>Replay</Typography>}>
                <Button
                  className={classes.button}
                  disabled={Store.index < 23}
                  color="primary"
                  onClick={() => Store.setIndex(0)}
                  variant="contained"
                  size="small"
                >
                  <ReplayIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Sunpath;
