import { Box3, Float32BufferAttribute, Vector2, Vector3 } from 'three';

const createUvMap = geometry => {
  const bboxSize = geometry.boundingBox.getSize();
  const uvMapSize = Math.min(bboxSize.x, bboxSize.y, bboxSize.z);
  const uvBbox = new Box3(
    new Vector3(-uvMapSize / 2, -uvMapSize / 2, -uvMapSize / 2),
    new Vector3(uvMapSize / 2, uvMapSize / 2, uvMapSize / 2)
  );

  const coords = [];
  coords.length = (2 * geometry.attributes.position.array.length) / 3;
  if (geometry.attributes.uv === undefined) {
    geometry.setAttribute('uv', new Float32BufferAttribute(coords, 2));
  }

  //maps 3 verts of 1 face on the better side of the cube
  //side of the cube can be XY, XZ or YZ
  const makeUVs = function (v0, v1, v2) {
    let n = new Vector3();
    n.crossVectors(v1.clone().sub(v0), v1.clone().sub(v2)).normalize();

    n.x = Math.abs(n.x);
    n.y = Math.abs(n.y);
    n.z = Math.abs(n.z);

    let uv0 = new Vector2();
    let uv1 = new Vector2();
    let uv2 = new Vector2();
    // xz mapping
    if (n.y > n.x && n.y > n.z) {
      uv0.x = (v0.x - uvBbox.min.x) / uvMapSize;
      uv0.y = (uvBbox.max.z - v0.z) / uvMapSize;

      uv1.x = (v1.x - uvBbox.min.x) / uvMapSize;
      uv1.y = (uvBbox.max.z - v1.z) / uvMapSize;

      uv2.x = (v2.x - uvBbox.min.x) / uvMapSize;
      uv2.y = (uvBbox.max.z - v2.z) / uvMapSize;
    } else if (n.x > n.y && n.x > n.z) {
      uv0.x = (v0.z - uvBbox.min.z) / uvMapSize;
      uv0.y = (v0.y - uvBbox.min.y) / uvMapSize;

      uv1.x = (v1.z - uvBbox.min.z) / uvMapSize;
      uv1.y = (v1.y - uvBbox.min.y) / uvMapSize;

      uv2.x = (v2.z - uvBbox.min.z) / uvMapSize;
      uv2.y = (v2.y - uvBbox.min.y) / uvMapSize;
    } else if (n.z > n.y && n.z > n.x) {
      uv0.x = (v0.x - uvBbox.min.x) / uvMapSize;
      uv0.y = (v0.y - uvBbox.min.y) / uvMapSize;

      uv1.x = (v1.x - uvBbox.min.x) / uvMapSize;
      uv1.y = (v1.y - uvBbox.min.y) / uvMapSize;

      uv2.x = (v2.x - uvBbox.min.x) / uvMapSize;
      uv2.y = (v2.y - uvBbox.min.y) / uvMapSize;
    }

    return {
      uv0: uv0,
      uv1: uv1,
      uv2: uv2,
    };
  };

  if (geometry.index) {
    // is it indexed buffer geometry?
    for (let vi = 0; vi < geometry.index.array.length; vi += 3) {
      let idx0 = geometry.index.array[vi];
      let idx1 = geometry.index.array[vi + 1];
      let idx2 = geometry.index.array[vi + 2];

      let vx0 = geometry.attributes.position.array[3 * idx0];
      let vy0 = geometry.attributes.position.array[3 * idx0 + 1];
      let vz0 = geometry.attributes.position.array[3 * idx0 + 2];

      let vx1 = geometry.attributes.position.array[3 * idx1];
      let vy1 = geometry.attributes.position.array[3 * idx1 + 1];
      let vz1 = geometry.attributes.position.array[3 * idx1 + 2];

      let vx2 = geometry.attributes.position.array[3 * idx2];
      let vy2 = geometry.attributes.position.array[3 * idx2 + 1];
      let vz2 = geometry.attributes.position.array[3 * idx2 + 2];

      let v0 = new Vector3(vx0, vy0, vz0);
      let v1 = new Vector3(vx1, vy1, vz1);
      let v2 = new Vector3(vx2, vy2, vz2);

      let uvs = makeUVs(v0, v1, v2, coords);

      coords[2 * idx0] = uvs.uv0.x;
      coords[2 * idx0 + 1] = uvs.uv0.y;

      coords[2 * idx1] = uvs.uv1.x;
      coords[2 * idx1 + 1] = uvs.uv1.y;

      coords[2 * idx2] = uvs.uv2.x;
      coords[2 * idx2 + 1] = uvs.uv2.y;
    }
  } else {
    for (let vi = 0; vi < geometry.attributes.position.array.length; vi += 9) {
      let vx0 = geometry.attributes.position.array[vi];
      let vy0 = geometry.attributes.position.array[vi + 1];
      let vz0 = geometry.attributes.position.array[vi + 2];

      let vx1 = geometry.attributes.position.array[vi + 3];
      let vy1 = geometry.attributes.position.array[vi + 4];
      let vz1 = geometry.attributes.position.array[vi + 5];

      let vx2 = geometry.attributes.position.array[vi + 6];
      let vy2 = geometry.attributes.position.array[vi + 7];
      let vz2 = geometry.attributes.position.array[vi + 8];

      let v0 = new Vector3(vx0, vy0, vz0);
      let v1 = new Vector3(vx1, vy1, vz1);
      let v2 = new Vector3(vx2, vy2, vz2);

      let uvs = makeUVs(v0, v1, v2, coords);

      let idx0 = vi / 3;
      let idx1 = idx0 + 1;
      let idx2 = idx0 + 2;

      coords[2 * idx0] = uvs.uv0.x;
      coords[2 * idx0 + 1] = uvs.uv0.y;

      coords[2 * idx1] = uvs.uv1.x;
      coords[2 * idx1 + 1] = uvs.uv1.y;

      coords[2 * idx2] = uvs.uv2.x;
      coords[2 * idx2 + 1] = uvs.uv2.y;
    }
  }
  geometry.attributes.uv.array = new Float32Array(coords);
};

export default createUvMap;
