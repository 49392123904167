import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import SearchIcon from '@material-ui/icons/Search';

import { useForm } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  error: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
  form: {
    width: '100%',
  },
  helperText: {
    margin: '3px 14px 0 14px ',
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  root: {
    alignItems: 'center',
    background: '#e7e7e8',
    display: 'flex',
    padding: '2px 4px',
    width: 400,
  },
}));

interface Props {
  error?: boolean;
  helperText?: string;
  name: string;
  onSubmit: <T>(args: T) => void;
  placeholder?: string;
  submitting?: boolean;
  type?: string;
}

const SearchInput: React.FC<Props> = ({
  error,
  helperText,
  name,
  onSubmit,
  placeholder,
  submitting,
  type = 'text',
}) => {
  const classes = useStyles();

  const { register, handleSubmit } = useForm();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          component="form"
          className={`${classes.root} ${error ? classes.error : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputBase
            className={classes.input}
            disabled={submitting}
            error={error}
            inputProps={{ 'aria-label': placeholder, type, name }}
            inputRef={register}
            placeholder={placeholder}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            disabled={submitting}
          >
            {submitting ? <CircularProgress /> : <SearchIcon />}
          </IconButton>
        </Paper>
      </Grid>
      <Grid item>
        <FormHelperText className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default SearchInput;
