import { BrowserRouter, Route } from 'react-router-dom';
import routes from './routes';

import shortid from 'shortid';

const Router = () => {
  return (
    <BrowserRouter>
      {routes.map(props => (
        <Route key={shortid()} {...props} />
      ))}
    </BrowserRouter>
  );
};

export default Router;
