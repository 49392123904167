import { useState, useEffect } from 'react';

import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import HelperButton from './HelperButton';

import axios from 'axios';

import map from 'lodash/map';

import Store from 'store/Store';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles(theme => ({
  title: {
    padding: '8px 16px',
    fontWeight: 'bold',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
}));

interface Props {}

const Polygons: React.FC<Props> = observer(() => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [processData, setProcessData] = useState({});
  const [shas, setShas] = useState({});

  useEffect(() => {
    const fetchProcessData = async () => {
      const {
        data: { step_metadata },
      } = await axios.get(
        `https://phx.gosolo.io/api/v1/point-cloud-process/storage-id/${Store.geoStorageId}`
      );
      const processData = map(step_metadata, ({ version }, key: string) => ({
        key,
        version,
      }));
      setProcessData(processData);
    };
    const fetchShas = async () => {
      const { data } = await axios.get(
        'https://phx.gosolo.io/api/v1/point-cloud-process/shas'
      );
      setShas(data);
    };
    fetchProcessData();
    fetchShas();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HelperButton onClick={handleClick} icon={<CloudCircleIcon />}>
        Pipeline Version
      </HelperButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <Typography className={classes.title} variant="h6">
            Current Versions
          </Typography>
          <Table>
            <TableBody>
              {map(shas, (version, deployment) => (
                <TableRow key={deployment}>
                  <TableCell>{deployment}</TableCell>
                  <TableCell>{version}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography className={classes.title} variant="h6">
            Process Data
          </Typography>
          <Table>
            <TableBody>
              {map(processData, ({ key, version }) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{version}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Menu>
    </>
  );
});

export default Polygons;
