import { useRef } from 'react';
import { Plane } from 'drei';
import { useFrame, useThree } from 'react-three-fiber';
import { CanvasTexture, DoubleSide } from 'three';

import useImage from 'use-image';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

const Ground = observer(() => {
  const { gl } = useThree();
  const [orthoImage, status] = useImage(
    `https://storage.googleapis.com/geo-storage/${Store.geoStorageId}/Standard_Ortho_RGB.jpeg`,
    'anonymous'
  );
  const groundMaterial = useRef();

  if (groundMaterial.current) {
    groundMaterial.current.color.convertSRGBToLinear();
  }

  const { center_pixel, perimeter, perimeter_in_pixels } =
    Store?.metadata?.texture_scaling;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const texture = new CanvasTexture(ctx.canvas);
  texture.anisotropy = gl.capabilities.getMaxAnisotropy();
  texture.needsUpdate = true;

  if (status === 'loaded' && Store?.metadata?.texture_scaling) {
    ctx.canvas.height = perimeter_in_pixels;
    ctx.canvas.width = perimeter_in_pixels;

    ctx.drawImage(
      orthoImage,
      -(center_pixel[0] - perimeter_in_pixels / 2),
      -(center_pixel[1] - perimeter_in_pixels / 2)
    );
  }

  useFrame(() => {
    if (texture) texture.needsUpdate = true;
  });
  if (Store.showWireframe) {
    return null;
  }

  return (
    <>
      <Plane
        args={[perimeter, perimeter]}
        position={[0, 0.01, -0.5]}
        rotation={[-Math.PI / 2, 0, 0]}
        receiveShadow
      >
        <meshLambertMaterial
          ref={groundMaterial}
          attach="material"
          map={texture}
          side={DoubleSide}
          toneMapped={false}
        >
          <canvasTexture attach="map" image={canvas} />
        </meshLambertMaterial>
      </Plane>
    </>
  );
});

export default Ground;
