import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Store from '../../store/Store';
import { observer } from 'mobx-react-lite';

import SearchInput from 'components/SearchInput';

import fetchGeoStorageMetadata from '../../services/fetchGeoStorageMetadata';

const StorageIdSearch: React.FC = observer(() => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const onSubmit = async ({ storageId }: any) => {
    if (!storageId) {
      return;
    }
    setNotFound(false);
    setSubmitting(true);
    const metadata = await fetchGeoStorageMetadata(storageId);
    if (metadata) {
      Store.setMetadata(metadata);
      history.push(`/${storageId}`);
      return;
    }
    setSubmitting(false);
    setNotFound(true);
  };
  return (
    <SearchInput
      error={notFound}
      helperText={notFound ? 'Geostorage not found, or metadata not ready' : ''}
      name="storageId"
      onSubmit={onSubmit}
      placeholder="Storage Id"
      submitting={submitting}
    />
  );
});

export default StorageIdSearch;
